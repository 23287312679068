import React from 'react';
import ReactMarkdown from 'react-markdown';

import { StoryblokStoryProps, getFilename, findMatchingLocalFileNode, DynamicImage } from 'src/lib/images';
import { Section } from 'src/components/core/Section';
import { HeroStoryblok } from 'src/storyblok-component-types';
import { Text } from 'src/components/core/typography';

export default (props: HeroStoryblok & StoryblokStoryProps) => {
    const imageFileNode = findMatchingLocalFileNode(getFilename(props.graphic?.filename || ""), props.story);
    return <Section className="grid grid-cols-12 grid-rows-1 items-center">
    <div className="order-12 md:order-1 md:col-start-1 col-span-12 md:col-span-7 lg:col-span-6">
        <ReactMarkdown>{props.title || ""}</ReactMarkdown>
        <Text className="text-lg">{props.description || ""}</Text>
    </div>
    <div className="order-1 md:order-12 col-span-12 md:col-span-5 lg:col-span-6 mb-6 md:mb-0 lg:ml-12 flex justify-center">
        <DynamicImage fileNode={imageFileNode} alt={props.title || "hero image"} className="max-w-[320px] md:max-w-full" />
    </div>
</Section>
};
