import React from 'react';

import { Section, SectionFullWidth } from 'src/components/core/Section';
import { TitleDescriptionStoryblok } from 'src/storyblok-component-types';
import { Header5, Text } from 'src/components/core/typography';

const BreakSentences = ({ text }: { text: string }) => {
    // Split the text by period followed by a space, question mark followed by a space, or exclamation mark followed by a space.
    const sentences = text.split(/(?<=[.!?])\s+/);

    return (
        <div>
            {sentences.map((sentence, index) => (
                <p key={index} className="!mb-0">{sentence}</p>
            ))}
        </div>
    );
};

export default ({ introData }: { introData: TitleDescriptionStoryblok }) => {
    return <SectionFullWidth>
        <div id="buslmform" className="bg-cover bg-[url('src/images/usx_business_bg.jpg')]">
            <Section className="grid grid-cols-12">
                <div className="col-span-12 text-center">
                    <Header5 className="!mb-0 font-semibold text-white">
                        {introData.description ? <BreakSentences text={introData.description} /> : ""}
                    </Header5>
                </div>
            </Section>
        </div>
    </SectionFullWidth>
};
