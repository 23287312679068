import React from 'react';
import ReactMarkdown from 'react-markdown';

import { StoryblokStoryProps, getFilename, findMatchingLocalFileNode, DynamicImage } from 'src/lib/images';
import { Section } from 'src/components/core/Section';
import { ResourceDownloadStoryblok } from 'src/storyblok-component-types';
import { Header2, Header3, Text } from 'src/components/core/typography';

import "./toolkit.scss";

const SplitList = ({ content }: { content: string }) => {
    // Extract list items from the Markdown content
    const listItems = content
        .split('\n')
        .filter((line) => line.startsWith('- ') || line.startsWith('* '))
        .map((item) => item.substring(2).trim());

    // Split list items into two columns
    const midIndex = Math.ceil(listItems.length / 2);
    const firstColumn = listItems.slice(0, midIndex);
    const secondColumn = listItems.slice(midIndex);

    return (
        <div className="grid md:grid-cols-2 gap-2 md:gap-4">
            <ul>
                {firstColumn.map((item, index) => (
                    <li key={index} className="text-bluewood list-disc ml-4 mb-2 last:mb-0 font-semibold">{item}</li>
                ))}
            </ul>
            <ul>
                {secondColumn.map((item, index) => (
                    <li key={index} className="text-bluewood list-disc ml-4 mb-2 last:mb-0 font-semibold">{item}</li>
                ))}
            </ul>
        </div>
    );
};

export default (props: ResourceDownloadStoryblok & StoryblokStoryProps) => {
    const { Title, Description, Content, DownloadLink, DownloadText } = props;

    // Extract non-list content
    const nonListContent = Content?.split('\n')
        .filter((line) => !line.startsWith('- ') && !line.startsWith('* '))
        .join('\n') || '';

    return (
        <Section className="grid grid-cols-12 grid-rows-1 gap-8">
            <div className="highlight-bold col-span-12 md:col-span-8 lg:col-span-9">
                <Header2>{Title || ""}</Header2>
                <ReactMarkdown className="!text-lg mb-8 text-bluewood">{Description || ""}</ReactMarkdown>
                <ReactMarkdown className="p">{nonListContent}</ReactMarkdown>
                <SplitList content={Content || ''} />
            </div>
            <div className="col-span-12 md:col-span-4 lg:col-span-3">
                <a className="btn btn-primary md:mr-4 mb-2 md:mb-0 capitalize" href={DownloadLink} rel="noopener" target="_blank">
                    {DownloadText}
                </a>
            </div>
        </Section>
    );
};