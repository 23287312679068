import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from 'src/components/layout';
import Head from 'src/components/head';
import { DataProps } from 'src/lib/storyblokSourceTypes';

import { HeroStoryblok, TitleDescriptionStoryblok, ResourceDownloadStoryblok, SeoStoryblok } from 'src/storyblok-component-types';
import { HeroSection, IntroSection, ResourceSection } from 'src/components/sections/toolkit';
import { SectionFullWidth } from "src/components/core/Section";

enum SLUGS {
    hero="toolkit/hero",
    allergen="toolkit/allergen",
    management="toolkit/management",
    haccp="toolkit/haccp",
    intro="toolkit/intro",
    seo="toolkit/seo"
}

export default ({ data }: PageProps<DataProps>) => {
    const slugs = data.allStoryblokEntry.nodes;
    const heroSlug = slugs.filter(slug => slug.full_slug === SLUGS.hero)[0];
    const allergenSlug = slugs.filter(slug => slug.full_slug === SLUGS.allergen)[0];
    const managementSlug = slugs.filter(slug => slug.full_slug === SLUGS.management)[0];
    const haccpSlug = slugs.filter(slug => slug.full_slug === SLUGS.haccp)[0];
    const introSlug = slugs.filter(slug => slug.full_slug === SLUGS.intro)[0];
    const seoSlug = slugs.filter(slug => slug.full_slug === SLUGS.seo)[0];

    // parsing "" into JSON will error out/fail builds.
    // this is intentional and indicates an error retrieving data from storyblok
    const heroContent: HeroStoryblok = JSON.parse(heroSlug?.content || "");
    const allergenContent: ResourceDownloadStoryblok = JSON.parse(allergenSlug?.content || "");
    const managementContent: ResourceDownloadStoryblok = JSON.parse(managementSlug?.content || "");
    const haccpContent: ResourceDownloadStoryblok = JSON.parse(haccpSlug?.content || "");
    const introContent: TitleDescriptionStoryblok = JSON.parse(introSlug?.content || "");
    const seoContent:SeoStoryblok = JSON.parse(seoSlug?.content || "");

    return <div>
    <Head seo={seoContent} storyblokData={seoContent}/>
    <Layout>
      <main>
        <HeroSection {...heroContent} story={heroSlug}/>
        <IntroSection introData={introContent} />
        <SectionFullWidth className="bg-gradient-to-b from-[#e1e4fe66] to-[#e1e4fe1a]">
          <ResourceSection {...allergenContent} story={allergenSlug} />
        </SectionFullWidth>
        <ResourceSection {...managementContent} story={managementSlug} />
        <SectionFullWidth className="bg-gradient-to-b from-[#e1e4fe66] to-[#e1e4fe1a]">
          <ResourceSection {...haccpContent} story={haccpSlug} />
        </SectionFullWidth>
      </main>
    </Layout>
  </div>;
}

export const pageQuery = graphql`
  query {
    allStoryblokEntry(filter: {full_slug: {regex: "/^toolkit.*/"}}) {
      nodes {
        content
        slug
        full_slug
        imageFileSrc {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    seo:allStoryblokEntry(filter: {full_slug: {eq: "seo"}}) {
      nodes {
        full_slug
        slug
        content
      }
    }
}`;
